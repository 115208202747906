.custom-add-house-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    background-color: #f4f4f9;
    min-height: 100vh;
    margin-top: 10vh;
}

/* Card container styling */
.custom-add-house-card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 100%;
    max-width: 600px;
    font-family: 'Roboto', sans-serif;
}

.custom-add-house-card h1 {
    font-family: 'Playfair Display', serif;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #2c3e50;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

/* Form group styling */
.custom-form-group {
    margin-bottom: 20px;
}

.custom-form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #34495e;
    font-size: 1.1rem;
}

/* Input and textarea styling */
.custom-form-group input,
.custom-form-group textarea {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease;
}

.custom-form-group input:focus,
.custom-form-group textarea:focus {
    border-color: #3498db;
    outline: none;
}

/* Image input styling */
.custom-image-input {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.custom-image-input input {
    flex: 1;
    margin-right: 15px;
}

.remove-image-btn {
    padding: 10px 15px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.remove-image-btn:hover {
    background-color: #c0392b;
}

/* Hide the original file input */
.custom-image-upload {
    display: none;
}

/* Style for the custom upload button */
.custom-upload-label {
    padding: 10px 20px;
    background-color: #2980b9;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    display: inline-block;
    width: 20vh;
}

.custom-upload-label:hover {
    background-color: #3498db;
}

/* Text next to the custom upload button showing the selected file */
.custom-file-name {
    display: inline-block;
    margin-left: 15px;
    font-size: 1rem;
    color: #2c3e50;
}


/* Add image button styling */
.custom-add-image-btn {
    padding: 10px 20px;
    background-color: #2980b9;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.custom-add-image-btn:hover {
    background-color: #3498db;
}

/* Submit button styling */
.custom-button {
    padding: 15px 20px;
    background-color: #27ae60;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 30px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.custom-button:hover {
    background-color: #2ecc71;
    transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .custom-add-house-card {
        padding: 20px;
    }

    .custom-add-house-card h1 {
        font-size: 2rem;
    }

    .custom-form-group input,
    .custom-form-group textarea {
        padding: 12px;
        font-size: 0.9rem;
    }

    .custom-button {
        padding: 12px 15px;
        font-size: 1rem;
    }
}
