/* Global CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  /* Services CSS */
  .services-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: #f8f5f0;
  }
  
  .services-title {
    font-family: 'Playfair Display', serif; /* פונט 'Playfair Display' */
    font-size: 2.8rem; /* גודל טקסט */
    color: #2c3e50; /* צבע טקסט */
    margin-bottom: 40px; /* מרווח תחתון */
    text-align: center; /* יישור למרכז */
    text-transform: uppercase; /* אותיות גדולות */
    letter-spacing: 1.5px; /* ריווח בין אותיות */
  }

  
  .services-image {
    flex: 1;
    max-width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 2%;
  }
  
  .services-image img {
    height: 70vh;
    width: 30vw;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .services-content {
    flex: 2;
    max-width: 65%;
    margin-left: 20px;
  }
  
  .services-title {
    font-family: 'Playfair Display', serif; /* עדכון לפונט 'Playfair Display' */
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .service-item {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .service-number {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    font-weight: bold;
    color: #c0a12e;
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.7;
  }
  
  .service-text {
    margin-top: 60px;
  }
  
  .service-item h3 {
    font-family: 'Playfair Display', serif;
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .service-item p {
    font-family: 'Roboto', sans-serif;
    color: #555;
    font-size: 1rem;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .services-container {
      flex-direction: column;
      padding: 20px;
    }
  
    .services-image {
      max-width: 100%;
      margin: 0 0 20px 0;
    }
  
    .services-image img {
      width: 80%;
      height: auto;
    }
  
    .services-content {
      max-width: 100%;
      margin-left: 0;
    }
  
    .services-title {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  
    .services-grid {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  
    .service-item {
      padding: 15px;
    }
  
    .service-number {
      font-size: 2.5rem;
      top: 5px;
      right: 10px;
    }
  
    .service-item h3 {
      font-size: 1.3rem;
    }
  
    .service-item p {
      font-size: 0.9rem;
    }
  }
  