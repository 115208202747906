/* Global CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Navbar CSS */
.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.custom-logo-container {
  flex: 1 1 auto; /* Allow logo container to shrink if necessary */
}

.custom-logo {
  max-height: 40px;
}

.custom-hamburger {
  display: block;
  font-size: 24px;
  cursor: pointer;
  z-index: 1100;
}

/* Nav Links */
.custom-nav-links-container {
  position: fixed;
  top: 10px;
  right: -200px; /* Initially hide off-screen */
  height: auto;
  width: 200px;
  background-color: white;
  border-radius: 8px; /* Rounded corners for a more elegant look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px;
  transition: right 0.3s ease; /* Smooth slide-in effect */
  z-index: 1000;
}

.custom-nav-links-container.open {
  right: 10px; /* Slide in the menu */
}

.custom-nav-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.custom-nav-link {
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  font-weight: 300;
  color: black;
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding: 8px 0;
  transition: color 0.3s ease;
  white-space: nowrap; /* Prevent text from wrapping */
}

.custom-nav-link:hover,
.custom-nav-link-active {
  color: #c0a12e;
}

.custom-hamburger.close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1100;
  font-size: 24px;
}

/* Desktop View */
@media (min-width: 769px) {
  .custom-navbar {
    padding: 20px 50px;
  }

  .custom-logo {
    max-height: 60px;
  }

  .custom-hamburger {
    display: none;
  }

  .custom-nav-links-container {
    position: static;
    display: flex;
    width: auto;
    height: auto;
    box-shadow: none;
    flex-direction: row;
    padding-top: 0;
    justify-content: center;
    align-items: center;
  }

  .custom-nav-links {
    flex-direction: row;
    gap: 40px;
  }

  .custom-nav-link {
    font-size: 22px;
    padding: 0;
    text-align: left;
    white-space: nowrap; /* Prevent text from wrapping */
  }
}
