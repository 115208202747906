.home {
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  margin-top: 0;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 1rem;
}

.logo img {
  max-width: 200px;
  margin-bottom: 1rem;
  margin-top: 5vh;
}

h1 {
  font-size: 1.5rem; /* Smaller font size for mobile */
  font-weight: 300;
  margin-bottom: 0.5rem;
}

h2 {
  font-family: 'Playfair Display', serif;
  font-size: 3rem; /* Smaller font size for mobile */
  font-weight: 700;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 2rem; /* Smaller font size for mobile */
  font-weight: 300;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem; /* Smaller font size for mobile */
  margin-bottom: 1.5rem;
}

.scroll-down {
  font-size: 2rem;
  cursor: pointer;
}

/* Media Queries */
@media (min-width: 768px) {
  .content {
    padding: 2rem;
  }

  h1 {
    font-size: 2rem; /* Larger font size for desktop */
  }

  h2 {
    font-size: 5rem; /* Larger font size for desktop */
  }

  h3 {
    font-size: 3rem; /* Larger font size for desktop */
  }

  p {
    font-size: 1.5rem; /* Larger font size for desktop */
  }

  .scroll-down {
    font-size: 2.5rem; /* Larger icon for desktop */
  }
}
