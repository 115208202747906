.office-section {
  padding: 50px 20px; /* Reduce padding for mobile */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column-reverse; /* Reverse order for mobile */
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.text-content {
  flex: 1;
  padding: 20px 0;
  text-align: center; /* Center-align text for mobile */
}

.text-content h1 {
  font-size: 2rem; /* Smaller font size for mobile */
  font-weight: 700;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 1rem; /* Smaller font size for mobile */
  line-height: 1.6;
  margin-bottom: 20px;
}

.read-more-btn {
  background-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  border: 2px solid black;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.read-more-btn:hover {
  background-color: black;
  color: white;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Add margin for mobile */
}

.image-content img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Media Queries for Desktop */
@media (min-width: 768px) {
  .container {
    flex-direction: row; /* Default order for desktop */
  }

  .text-content {
    padding-right: 50px;
    text-align: left; /* Left-align text for desktop */
  }

  .text-content h1 {
    font-size: 3rem; /* Larger font size for desktop */
  }

  .text-content p {
    font-size: 1.2rem; /* Larger font size for desktop */
    margin-bottom: 30px;
  }

  .image-content {
    margin-bottom: 0; /* Remove margin for desktop */
  }
}
