.custom-manage-houses-container {
  padding: 40px;
  background-color: #f4f4f9;
  font-family: 'Roboto', sans-serif;
}

.custom-manage-houses-container h1 {
  font-family: 'Playfair Display', serif;
  margin-bottom: 40px;
  font-size: 2.8rem;
  color: #2c3e50;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.custom-house-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-house-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.custom-house-item h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  margin-bottom: 15px;
  color: #34495e;
}

.custom-house-item p {
  margin: 8px 0;
  font-family: 'Roboto', sans-serif;
  color: #7f8c8d;
  font-size: 16px;
}

.custom-manage-buttons {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}

.custom-manage-buttons button {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.custom-manage-buttons button:hover {
  background-color: #2ecc71;
  transform: translateY(-2px);
}

.custom-manage-buttons .delete-btn {
  background-color: #e74c3c;
}

.custom-manage-buttons .delete-btn:hover {
  background-color: #c0392b;
}

.custom-edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.custom-edit-form input,
.custom-edit-form textarea {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: border-color 0.3s ease;
}

.custom-edit-form input:focus,
.custom-edit-form textarea:focus {
  border-color: #3498db;
  outline: none;
}

.custom-form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.custom-image-input {
  display: flex;
  gap: 10px;
  align-items: center;
}

.custom-image-input input {
  flex-grow: 1;
  padding: 10px;
}

.remove-image-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-image-btn:hover {
  background-color: #c0392b;
}

.custom-add-image-btn {
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 20vh;
  margin-top: 10px;
}

.custom-add-image-btn:hover {
  background-color: #3498db;
}

.custom-button-group {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.custom-edit-form button[type="submit"],
.custom-edit-form button[type="button"] {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.custom-edit-form button[type="submit"]:hover,
.custom-edit-form button[type="button"]:hover {
  background-color: #34495e;
  transform: translateY(-2px);
}

.custom-upload-label1 {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
  width: 20vh;
}

.custom-upload-label1:hover {
  background-color: #2980b9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .custom-manage-houses-container h1 {
    font-size: 2rem;
  }

  .custom-house-item h2 {
    font-size: 20px;
  }

  .custom-manage-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .custom-manage-buttons button {
    width: 100%;
  }

  .custom-edit-form {
    gap: 10px;
  }

  .custom-edit-form input,
  .custom-edit-form textarea {
    padding: 12px;
    font-size: 14px;
  }

  .custom-button-group {
    flex-direction: column;
  }

  .custom-edit-form button[type="submit"],
  .custom-edit-form button[type="button"] {
    width: 100%;
  }

  .custom-add-image-btn {
    width: 100%;
  }
}
