/* Base map container styles */
.map-container {
  width: 80%;
  height: 500px;
  margin: 20px auto;
  border: 2px solid #ccc;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

/* Fullscreen map container styles */
.map-container.fullscreen {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  margin: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

.fullscreen-toggle-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1; /* הורדנו את ה-z-index כדי שהכפתור לא יעלה מעל אלמנטים אחרים */
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fullscreen-toggle-btn:hover {
  background-color: #f0f0f0;
}

/* Info window content styles */
.info-window-content {
  text-align: center;
  width: 180px;
}

.info-window-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.info-window-title {
  font-size: 1rem;
  margin: 6px 0;
  color: #333;
}

.info-window-price {
  font-size: 0.9rem;
  color: #2e8b57;
  font-weight: bold;
}

/* Button styles */
.btn1 {
  background-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  border: 2px solid black;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.btn1:hover {
  background-color: black;
  color: white;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .map-container {
    width: 90%;
    height: 505px;
    margin: 10px auto;
  }

  .info-window-content {
    width: 160px;
  }

  .info-window-title {
    font-size: 0.9rem;
  }

  .info-window-price {
    font-size: 0.8rem;
  }

  .btn1 {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
