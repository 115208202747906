.house-detail-wrapper {
    max-width: 1400px;
    margin: 50px auto;
    padding: 30px;
    border: 2px solid #ddd;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #f3f4f6;
    position: relative;
    margin-top: 8%;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.house-detail-wrapper:before,
.house-detail-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 8px;
    background-color: #d4af37;
}

.house-detail-wrapper:before {
    left: -10px;
    border-radius: 0 10px 10px 0;
}

.house-detail-wrapper:after {
    right: -10px;
    border-radius: 10px 0 0 10px;
}

.house-detail-primary-image {
    width: 100%;
    height: 50vh;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 20px;
    border: 4px solid #d4af37;
}

.house-detail-primary-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.house-detail-secondary-images-wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    width: 91vw;
}

.house-detail-secondary-images {
    display: flex;
    gap: 10px;
    transition: transform 0.3s ease;
}

.house-detail-secondary-images img {
    width: calc(20% - 8px);
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    border: 2px solid #d4af37;
    transition: transform 0.2s ease-in-out;
}

.house-detail-secondary-images img:hover {
    transform: scale(1.05);
}

.house-detail-carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 15px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;
    font-size: 24px;
    transition: background-color 0.3s ease;
}

.house-detail-carousel-button:hover {
    background-color: #d4af37;
}

.house-detail-carousel-button.left {
    left: 10px;
}

.house-detail-carousel-button.right {
    right: 10px;
}

.house-detail-content {
    padding: 40px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    position: relative;
    border: 3px solid #d4af37;
}

.house-detail-content:before,
.house-detail-content:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 8px;
    background-color: #d4af37;
}

.house-detail-content:before {
    left: 0;
}

.house-detail-content:after {
    right: 0;
}

.house-detail-title {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.house-detail-address {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #777;
    text-align: center;
}

.house-detail-subtitle {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #777;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
}

.house-detail-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 30px;
}

.house-detail-item {
    text-align: center;
    background-color: #f7f7f7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border: 2px solid #d4af37;
    position: relative;
    transition: transform 0.2s ease-in-out;
}

.house-detail-item:hover {
    transform: translateY(-5px);
}

.house-detail-item span {
    display: block;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-top: 15px;
}

.house-detail-item label {
    display: block;
    font-size: 1.1rem;
    color: #777;
}

.house-detail-item i {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3rem;
    color: #d4af37;
}

.house-detail-description {
    font-size: 1.2rem;
    color: #555;
    margin-top: 30px;
    text-align: center;
}

.house-map-container {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    margin-top: 20px;
    border: 2px solid #d4af37;
}

.house-detail-location-map h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

@media (max-width: 768px) {
    .house-detail-wrapper {
        padding: 20px;
        margin: 20px auto;
        margin-top: 10vh;
    }

    .house-detail-primary-image {
        height: 40vh;
    }

    .house-detail-secondary-images img {
        width: calc(33.33% - 6px);
    }

    .house-detail-title {
        font-size: 2.5rem;
    }

    .house-detail-address {
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .house-detail-subtitle {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }

    .house-detail-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .house-detail-item {
        padding: 20px;
    }

    .house-detail-item span {
        font-size: 1.5rem;
    }

    .house-detail-item label {
        font-size: 1rem;
    }

    .house-detail-item i {
        top: -25px;
        font-size: 2.5rem;
    }

    .house-detail-description {
        font-size: 1rem;
    }

    .house-map-container {
        height: 150px;
    }

    .house-detail-location-map h2 {
        font-size: 2rem;
    }
}
