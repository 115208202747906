.custom-latest-properties {
    padding: 20px 0;
    background-color: #f8f5f0;
    text-align: center;
}

.custom-latest-properties h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.custom-properties-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 15px;
    justify-content: center;
}

.custom-property-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.custom-property-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custom-property-image-wrapper {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.custom-carousel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.custom-carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 20;
    font-size: 16px;
}

.custom-carousel-button.left {
    left: 10px;
}

.custom-carousel-button.right {
    right: 10px;
}

.custom-property-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.custom-property-image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    z-index: 1;
    pointer-events: none;
}

.custom-property-price-overlay {
    position: absolute;
    bottom: -10px;
    left: 15px;
    font-weight: bold;
    z-index: 2;
    color: #fff;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.9);
}

.custom-property-price {
    font-size: 1.7rem;
}

.custom-property-details {
    padding: 15px;
    text-align: left;
}

.custom-property-title {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 10px 0;
    color: #333;
}

.custom-property-address {
    color: #666;
    margin-bottom: 10px;
    font-size: 0.9rem;
}

.custom-property-info {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #726e6e;
    position: relative;
}

.custom-property-info p {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0 10px;
    text-align: center;
    position: relative;
}

.custom-property-info p span {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 3px;
}

.custom-property-info p label {
    font-size: 0.8rem;
}

/* קו מפריד בין פרטים */
.custom-property-info p:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #ddd;
    top: 0;
}

.custom-load-more-btn {
    display: block;
    margin: 20px auto;
    background-color: transparent;
    border: 2px solid black;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    z-index: 10; /* Ensure the button stays above other content */
    position: relative;
}

.custom-load-more-btn:hover {
    background-color: #000000;
    color: white;
    transform: translateY(-2px);
}


.custom-map-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-map-text {
    font-size: 0.75rem;
    color: #FF0000;
    margin-bottom: 0.2rem;
    text-align: center;
}

.custom-map-icon {
    font-size: 1.5rem;
    color: #FF0000;
    cursor: pointer;
}

.custom-map-icon:hover {
    color: darkred;
}

.custom-property-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Media Queries for Desktop */
@media (min-width: 768px) {
    .custom-properties-grid {
        padding: 0 30px;
    }

    .custom-property-card {
        width: calc(30% - 20px);
        height: 400px;
    }

    .custom-property-info p span {
        font-size: 1.5rem;
    }
}



