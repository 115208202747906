/* Contact Section Styles */
.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    background-color: #f8f5f0; /* Background color for the section */
}

.contact-container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    background-color: #f6f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    flex-direction: row; /* Default to row for desktop */
}

/* Image Styling */
.contact-image {
    flex: 1;
    max-width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.contact-image img {
    height: 60vh;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form Styling */
.contact-form {
    flex: 1;
    padding: 40px;
}

.contact-form h2 {
    font-family: 'Playfair Display', serif; /* Updated font */
    font-size: 24px;
    margin-bottom: 20px; /* Adjusted margin */
    text-align: left;
    color: #333;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-group {
    flex: 1;
    margin-right: 10px;
}

.form-group.half-width {
    flex: 0.495;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 15px; /* Adjusted padding for better spacing */
    margin-bottom: 5px; /* Reduced margin for smaller spacing */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

textarea {
    height: 100px;
}

.form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.send-message-button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
}

.send-message-button:hover {
    background-color: #555;
}

.credit-section {
    margin-top: 10px;
}

.credit-text {
    font-size: 12px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.small-social-icons {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 5px;
}

.small-social-icons a {
    color: #999;
    font-size: 20px;
    transition: color 0.3s ease;
}

.small-social-icons a:hover {
    color: #555;
}

.social-icons {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    justify-content: flex-end;
    flex: 1;
}

.social-icons a {
    color: #333;
    font-size: 28px;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #555;
}

/* Mobile-Specific Adjustments */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column; /* Stack the form and image vertically */
        margin-bottom: -14%;
    }

    .contact-image {
        max-width: 100%; /* Full width on mobile */
        margin-bottom: 20px; /* Add space between image and form */
    }

    .contact-image img {
        width: 80%;
        height: auto; /* Ensure the image fits nicely */
        border-radius: 5; /* Remove border-radius on mobile */
    }

    .contact-form {
        padding: 20px; /* Reduced padding for mobile */
    }

    .form-row {
        flex-direction: column; /* Stack inputs vertically */
        margin-bottom: 2px; /* Ensure consistent spacing between rows */
    }

    .form-group {
        margin-right: 0; /* Remove margin for better spacing */
        margin-bottom: 10px; /* Consistent spacing between fields */
    }

    .send-message-button {
        width: 100%; /* Full width button on mobile */
        margin-top: 20px;
    }
}
