/* עיצוב כללי */
.testimonials-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.testimonials-section h2 {
    font-family: 'Playfair Display', serif; /* עדכון לפונט 'Playfair Display' */
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
    font-weight: 700; /* עובי פונט מודגש */
    text-transform: uppercase; /* הכותרת באותיות גדולות */
    letter-spacing: 1.5px; /* ריווח בין אותיות */
}

.testimonial-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 800px;
    margin: 0 auto;
}

.testimonial {
    max-width: 600px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    min-height: 250px; /* קביעת גובה מינימלי אחיד לכל הכרטיסיות */
}

.testimonial-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: cover;
}

.testimonial-comment {
    font-size: 1.2rem;
    font-style: italic;
    color: #555;
    margin-bottom: 15px;
}

.testimonial-name {
    font-size: 1rem;
    color: #777;
}

.testimonial-arrow {
    background-color: transparent;
    border: none;
    color: #c0a12e;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    transition: color 0.3s ease;
}

.testimonial-arrow:hover {
    color: #a8852e;
}

.testimonial-arrow.left {
    left: -20px; /* שינוי המרחק מהשוליים */
}

.testimonial-arrow.right {
    right: -20px; /* שינוי המרחק מהשוליים */
}

/* עיצוב מותאם לטלפון */
@media (max-width: 768px) {
    .testimonials-section h2 {
        font-size: 2rem; /* התאמת גודל הכותרת */
        margin-bottom: 20px;
    }

    .testimonial-container {
        max-width: 100%; /* התאמת הרוחב למסכים קטנים */
        padding: 0 10px; /* רווח פנימי */
    }

    .testimonial {
        max-width: 100%; /* התאמת הרוחב למסכים קטנים */
        padding: 15px; /* ריווח פנימי */
        min-height: 250px; /* קביעת גובה מינימלי אחיד */
    }

    .testimonial-image {
        width: 70px; /* הקטנת התמונה */
        height: 70px;
        margin-bottom: 15px;
    }

    .testimonial-comment {
        font-size: 1rem; /* הקטנת גודל הפונט */
        margin-bottom: 10px;
    }

    .testimonial-name {
        font-size: 0.9rem; /* הקטנת גודל הפונט */
    }

    .testimonial-arrow {
        font-size: 1.5rem; /* הקטנת גודל החצים */
        top: 15px; /* הזזת החצים למעלה */
    }

    .testimonial-arrow.left {
        left: 30px; /* קרוב יותר למרכז */
    }

    .testimonial-arrow.right {
        right: 30px; /* קרוב יותר למרכז */
    }
}
