/* Overlay styling for the modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker background for a more dramatic effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Content box for the modal */
.modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
    width: 350px; /* Slightly wider for a more comfortable layout */
    font-family: 'Playfair Display', serif; /* Matching font with the rest of the site */
}

/* Header text styling */
.modal-content h2 {
    margin-bottom: 20px;
    font-size: 1.8rem; /* Larger font size */
    color: #333;
}

/* Input styling */
.modal-content input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 8px; /* Softer rounded corners */
    border: 1px solid #ccc;
    font-size: 1rem; /* Slightly larger font size */
}

/* Grouping buttons for better layout control */
.button-group {
    display: flex;
    justify-content: space-between;
}

/* Button styling */
.modal-content button {
    padding: 12px 20px; /* More padding for better touch interaction */
    background-color: #c0a12e;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    font-family: 'Roboto', sans-serif; /* Secondary font for buttons */
}

/* Hover effect for buttons */
.modal-content button:hover {
    background-color: #9b8525;
}

/* Specific styling for the Cancel button */
.modal-content button[type="button"] {
    background-color: #aaa;
}

.modal-content button[type="button"]:hover {
    background-color: #888;
}

/* Mobile-Specific Adjustments */
@media (max-width: 768px) {
    .modal-content {
        width: 90%; /* Full width on mobile for better accessibility */
        padding: 20px; /* Reduced padding for a better fit on smaller screens */
    }

    .modal-content h2 {
        font-size: 1.5rem; /* Slightly smaller font size for mobile */
    }

    .modal-content input {
        font-size: 0.9rem; /* Slightly smaller font size for mobile */
        padding: 10px; /* Reduced padding for smaller inputs */
    }

    .modal-content button {
        padding: 10px 15px; /* Smaller buttons for mobile */
        font-size: 0.9rem; /* Adjust font size for better fit */
    }
}
